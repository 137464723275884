module.exports = [{
      plugin: require('../node_modules/@mobile-reality/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleConsent":{"adStorage":"gatsby-gdpr-google-ad-storage","analyticsStorage":"gatsby-gdpr-google-analytics-storage","functionalityStorage":"gatsby-gdpr-google-functionality-storage","personalizationStorage":"gatsby-gdpr-google-personalization-storage","adUserData":"gatsby-gdpr-google-ad-user-data","adPersonalization":"gatsby-gdpr-google-ad-personalization","waitForUpdate":500},"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","trackingId":"G-15778JNK64","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","dataLayerName":"dataLayer"},"googleTag":{"trackingIds":[]},"facebookPixel":{"pixelId":"764806988969868","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"","cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"trackingId":"","cookieName":"gatsby-gdpr-linkedin"},"hubspot":{"trackingId":"","cookieName":"gatsby-gdpr-hubspot"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["bg","en","ru"],"defaultLanguage":"bg","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grand","short_name":"Grand","description":"Grand - Най-големият лидер в производството на всякакви видове кашони и опаковки!","start_url":"/","background_color":"#0c1418","theme_color":"#0c1418","display":"minimal-ui","icon":"src/images/Logo-min.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5e9628c6b27f5c9b34c6911644247f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
