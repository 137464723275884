exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-top-interesting-facts-about-cardboard-boxes-tsx": () => import("./../../../src/pages/blog/top-interesting-facts-about-cardboard-boxes.tsx" /* webpackChunkName: "component---src-pages-blog-top-interesting-facts-about-cardboard-boxes-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-crate-boxes-tsx": () => import("./../../../src/pages/products/crate-boxes.tsx" /* webpackChunkName: "component---src-pages-products-crate-boxes-tsx" */),
  "component---src-pages-products-food-boxes-tsx": () => import("./../../../src/pages/products/food-boxes.tsx" /* webpackChunkName: "component---src-pages-products-food-boxes-tsx" */),
  "component---src-pages-products-grand-playhouse-tsx": () => import("./../../../src/pages/products/grand-playhouse.tsx" /* webpackChunkName: "component---src-pages-products-grand-playhouse-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-luxury-laminated-boxes-tsx": () => import("./../../../src/pages/products/luxury-laminated-boxes.tsx" /* webpackChunkName: "component---src-pages-products-luxury-laminated-boxes-tsx" */),
  "component---src-pages-products-octabin-boxes-tsx": () => import("./../../../src/pages/products/octabin-boxes.tsx" /* webpackChunkName: "component---src-pages-products-octabin-boxes-tsx" */),
  "component---src-pages-products-pallet-boxes-tsx": () => import("./../../../src/pages/products/pallet-boxes.tsx" /* webpackChunkName: "component---src-pages-products-pallet-boxes-tsx" */),
  "component---src-pages-products-pizza-boxes-tsx": () => import("./../../../src/pages/products/pizza-boxes.tsx" /* webpackChunkName: "component---src-pages-products-pizza-boxes-tsx" */),
  "component---src-pages-products-punched-boxes-tsx": () => import("./../../../src/pages/products/punched-boxes.tsx" /* webpackChunkName: "component---src-pages-products-punched-boxes-tsx" */),
  "component---src-pages-products-transport-boxes-tsx": () => import("./../../../src/pages/products/transport-boxes.tsx" /* webpackChunkName: "component---src-pages-products-transport-boxes-tsx" */),
  "component---src-pages-products-wine-boxes-tsx": () => import("./../../../src/pages/products/wine-boxes.tsx" /* webpackChunkName: "component---src-pages-products-wine-boxes-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

